<template>
  <div class="recharge-activity-container">
    <div class="recharge-activity-wrapper p-relative" :class="theme">
      <img class="vip-close-btn" @click="closeBtnClick" src="../assets/theme1/vip-close-btn.png" v-if="theme == 'theme1'" />
      <img class="vip-close-btn" @click="closeBtnClick" src="../assets/vip-close-btn.png" v-else />
      <div class="flex-center vip-title" :class="theme">
        钱包教程中心
      </div>
      <div class="wallet-wrapper flex">
        <div class="wallet-item-w flex" v-for="item of walletDataList" :class="theme">
          <div class="wallet-name-wrapper flex" :class="theme">
            <div class="wallet-ico">
              <img :src="`/image/ico-wallet${item.id}.png`"/>
            </div>
            {{item.title}}
          </div>
          <div class="tool-btn flex-center" :class="theme">
            <a target="_blank" :href="item.tutorialDownloadUrl">下载教程</a>
          </div>
          <div class="tool-btn flex-center" :class="theme">
            <a target="_blank" :href="item.tutorialBuyUrl">买卖教程</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      walletDataList:[],
      theme:'',
    }
  },
  mounted(){
    this.getWalletList();
    this.theme = globalConfig.theme;
  },
  methods:{
    getWalletList(){
      this.walletDataList = window.globalConfig.tutorisWallet;
    },
    closeBtnClick(){
      window.globalConfig.showPanelByKey('')
    }
  }
}
</script>

<style scoped lang="stylus">
.recharge-activity-container
  width 100%
  height 100%
  position fixed
  z-index 2
  top 0
  left 0
  background-color rgba(0,0,0,.4)
.recharge-activity-wrapper
  width 7.58rem
  height 3.88rem
  padding-top .12rem
  border-radius .2rem
  position absolute
  z-index 1
  top 50%
  left 50%
  transform translateX(-50%)  translateY(-50%)
  background-image url(../assets/acc-member-bg.png)
  background-size 100% 100%
  &.theme1
    background-image url(../assets/theme1/acc-bonus-background.png)
  .vip-close-btn
    position absolute
    z-index 1
    top -.12rem
    right -.14rem
    width .48rem
    cursor pointer
  .vip-title
    font-size .46rem
    font-weight bold
    background-image -webkit-linear-gradient(#F8AE6D,#E29C60,#9f673e)
    -webkit-background-clip text;
    -webkit-text-fill-color transparent
    &.theme1
      background-image linear-gradient(#f7eecc,#f3e6c8,#f3e6c8,#c29b48,#ead3a0)
  .wallet-wrapper
    width 90%
    margin auto
    height auto
    flex-wrap wrap
    padding-top .24rem
    .wallet-item-w
      width 50%
      height .35rem
      padding 0 .04rem
      margin .04rem 0
      color #FFFDFA
      font-weight bold
      flex-shrink 0
      justify-content space-between
      & > div
        cursor pointer
      &.theme1
        color #FFFFFF
      .wallet-name-wrapper
        width 32%
        height .35rem
        align-items center
        border-radius .04rem
        background-image url(../assets/toturis-btn-bg.png)
        background-size 100% 100%
        &.theme1
          background-image url(../assets/theme1/sign-btn-bg.png)
        .wallet-ico
          width .26rem
          height .26rem
          margin 0 .04rem 0 .06rem
          display flex
          align-items center
      .tool-btn
        width 32%
        height .35rem
        background-color #A37462
        border-radius .04rem
        a
          color #ffffff
          text-decoration none
        &.theme1
          background #49372f

</style>
