<template>
  <div class="recharge-activity-container act-detail">
    <div class="recharge-activity-wrapper p-relative" :class="theme">
      <img class="vip-close-btn" @click="closeBtnClick" src="../assets/theme1/vip-close-btn.png"
        v-if="theme == 'theme1'" />
      <img class="vip-close-btn" @click="closeBtnClick" src="../assets/vip-close-btn.png" v-else />
      <div class="flex-center vip-title aaa" :class="theme">
        充值最高送500%
      </div>
      <div class="bonus-description" :class="theme">
        {{rechargeDesc}}
      </div>
      <div class="flex re-act" :class="theme">
        <div class="recharge-act-item p-relative" :class="`back${i + 1}`" v-for="(itme, i) of rechargeActItem">
          <div class="bonus-item r">
            最低存款额:{{ itme.min_recharge }}
          </div>
          <div class="bonus-item">
            最高奖励:{{ itme.max_amount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      theme: '',
      rechargeActItem: [],
      rechargeDesc:''
    }
  },
  mounted() {
    this.theme = globalConfig.theme;

    this.getActiveRechargeData().then(data => {
      if(data){
        this.info = data;
        this.rechargeActItem = data.rules;
        this.rechargeDesc = data.desc;
      }
    })
  },
  methods: {
    async getActiveRechargeData(){
      let userName = localStorage.getItem('username');
      if(!userName) return;
      let result = null;
      await axios.get(`${globalConfig.api_domain}/act_recharge?username=${userName}`).then(response => {
        if (parseInt(response.data.code) === 1) {
          alert(response.data.message);
        } else {
          localStorage.setItem('act_recharge_data', JSON.stringify(response.data.data));
          result = response.data.data;
        }
      }).catch(error => {
        console.error("There was an error fetching the data", error);
      });
      return result;
    },
    closeBtnClick() {
      window.globalConfig.showPanelByKey('recharge-act')
    }
  }
}
</script>

<style scoped lang="stylus">
.recharge-activity-container
  width 100%
  height 100%
  position fixed
  z-index 2
  top 0
  left 0
  background-color rgba(0,0,0,.4)
.recharge-activity-wrapper
  width 7.68rem
  height 3.5rem
  padding-top .12rem
  position absolute
  z-index 1
  top 50%
  left 50%
  transform translateX(-50%) translateY(-50%)
  background-image url(../assets/acc-member-bg.png)
  background-size 100% 100%
  &.theme1
    background-image url(../assets/theme1/acc-bonus-background.png)
  .vip-close-btn
    position absolute
    z-index 1
    top -.12rem
    right -.14rem
    width .48rem
    cursor pointer
  .vip-title
    font-size .46rem
    font-weight bold
    background-image -webkit-linear-gradient(#F8AE6D,#E29C60,#9f673e)
    -webkit-background-clip text;
    -webkit-text-fill-color transparent
    &.theme1
      background-image linear-gradient(#f7eecc,#f3e6c8,#f3e6c8,#c29b48,#ead3a0)
  .bonus-description
    color #6E6E6E
    width 85%
    margin .18rem auto
    font-size .22rem
    &.theme1
      color #E6C87D
  .re-act
    justify-content center
    .recharge-act-item
      width 1.08rem
      height 1.5rem
      margin-right .1rem
      background-size 100% 100%
      &.back1
        background-image url(../assets/re-dec-ico1.png)
      &.back2
        background-image url(../assets/re-dec-ico2.png)
      &.back3
        background-image url(../assets/re-dec-ico3.png)
      &.back4
        background-image url(../assets/re-dec-ico4.png)
      &.back5
        background-image url(../assets/re-dec-ico5.png)
      &.back6
        background-image url(../assets/re-dec-ico6.png)
      &:last-child
        margin-right 0
      .bonus-item
        font-size .12rem
        position absolute
        left 0
        width 108%
        bottom .1rem
        padding-left .06rem
        &.r
          bottom .24rem
    &.theme1
      .recharge-act-item
        &.back1
          background-image url(../assets/theme1/re-dec-ico1.png)
        &.back2
          background-image url(../assets/theme1/re-dec-ico2.png)
        &.back3
          background-image url(../assets/theme1/re-dec-ico3.png)
        &.back4
          background-image url(../assets/theme1/re-dec-ico4.png)
        &.back5
          background-image url(../assets/theme1/re-dec-ico5.png)
        &.back6
          background-image url(../assets/theme1/re-dec-ico6.png)
  .go-recharge-btn
    width 1.08rem
    margin .2rem 0 0 .3rem
</style>
