<template>
  <div class="recharge-activity-container">
    <div class="recharge-activity-wrapper sssss p-relative" :class="theme">
      <div class="flex-center vip-title" :class="theme">
        <img class="vip-close-btn" @click="closeBtnClick" src="../assets/theme1/vip-close-btn.png" v-if="theme == 'theme1'" />
        <img class="vip-close-btn" @click="closeBtnClick" src="../assets/vip-close-btn.png" v-else />
        <img class="fvip-ico" src="../assets/theme1/fvip-ico-1.png" v-if="theme == 'theme1'" />
        <img class="fvip-ico" src="../assets/fvip-ico-1.png" v-else />
        <div style="margin:0 .2rem">金融VIP</div>
        <img class="fvip-ico" src="../assets/theme1/fvip-ico-2.png" v-if="theme == 'theme1'" />
        <img class="fvip-ico" src="../assets/fvip-ico-2.png" v-else />
      </div>
      <div class="vip-body" :class="theme">
        <div>
          <div class="area-title">
            活动详情
          </div>
          <div v-html="contentHtmlStr">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data(){
    return{
      theme:'',
      bonusDescriptionList:[],
      contentHtmlStr:'',
    }
  },
  mounted(){
    this.theme = globalConfig.theme;
    this.routeHandle()
    this.bonusDescriptionList = globalConfig.bonusDescriptionList;
    this.getApiData();
  },
  methods:{
    getApiData(){
      axios.get(`${globalConfig.api_domain}/act_recharge/act_rules`).then(res => {
        if(res.data && res.data.data){
          this.contentHtmlStr = res.data.data.act_rules;
        }
      })
    },
    routeHandle(){
      if(this.theme == 'theme1'){
        document.querySelector('body').style.backgroundColor = '#000000'
      }else{
        document.querySelector('body').style.backgroundColor = '#ffffff'
      }
    },
    closeBtnClick(){
      window.globalConfig.showPanelByKey('')
    }
  }
}
</script>

<style scoped lang="stylus">
.recharge-activity-container
  width 100%
  height 100%
  position fixed
  z-index 2
  top 0
  left 0
  background-color rgba(0,0,0,.4)
.recharge-activity-wrapper
  width 8rem
  padding .12rem 0 .3rem 0
  position absolute
  z-index 1
  top 50%
  left 50%
  transform translateX(-50%) translateY(-50%)
  background-image url(../assets/acc-page-container.png)
  background-size 100% 100%
  &.theme1
    background-image url(../assets/theme1/acc-bonus-background.png)
  &:after
    content ' '
    position absolute
    left 0
    bottom -.98rem
    display block
    width 1rem
    height .5rem
  .vip-close-btn
    position absolute
    z-index 1
    top -.12rem
    right -.14rem
    width .48rem
    cursor pointer
  .vip-title
    font-size .46rem
    margin-bottom .24rem
    font-weight bold
    background-image -webkit-linear-gradient(#F8AE6D,#E29C60,#9f673e)
    -webkit-background-clip text;
    -webkit-text-fill-color transparent
    &.theme1
      background-image linear-gradient(#f7eecc,#f3e6c8,#f3e6c8,#c29b48,#ead3a0)
    .fvip-ico
      width 2.22rem
</style>

<!-- 以下已废弃 -->
<style lang="stylus" scoped>
  .vip-body
    height 4.7rem
    overflow auto
    color #050402
    padding 0 .32rem 0 .43rem
    &::-webkit-scrollbar
      width 0
    .f-vip-content
      display block
      width 7rem
      margin auto
    &::-webkit-scrollbar-thumb
      background #000000
    .area-title
      background-image linear-gradient(#F7B664,#FFAA61,#E89549,#DE804C)
      -webkit-background-clip text;
      -webkit-text-fill-color transparent
      font-size .22rem
      border-bottom 1px solid #D48341
      width 100%
      margin auto
      font-weight bold
      padding 0 0 .05rem .05rem
      &.activity
        margin-top .24rem
    .big-word-wrapper
      width 7rem
      margin .12rem auto 0 auto
      padding-left .05rem
    .wenisi-description
      width 7rem
      margin auto
      font-size: .12rem;
      padding: 0.05rem;
    .item-table
      width: 86.6%;
      margin:.1rem 0 0 .52rem;
      border-top: 1px solid #d48341;
      border-left: 1px solid #d48341;
      .item-r-w
        width 100%
        .item-c-w
          width 50%
          border-right 1px solid #d48341
          border-bottom 1px solid #d48341
          height .38rem
        &.item-t-w
          .item-c-w
            background-image linear-gradient(#FFB36B,#EF9855,#C98850)
            -webkit-background-clip text;
            -webkit-text-fill-color transparent
            font-weight bold
            font-size .18rem
    .promotion-criteria
      width 86.6%
      margin .28rem 0 .26rem 0.52rem
      border-top 1px solid #d48341
      border-left 1px solid #d48341
      .p-c-row
        &.head-row
          background-image linear-gradient(#ffb36b, #ef9855, #c98850)
          -webkit-background-clip text;
          -webkit-text-fill-color transparent
          font-weight bold
          .big
            font-size .16rem
          .level-title
            flex-shrink 0
            width 1.38rem
            border-right 1px solid #d48341
            border-bottom 1px solid #d48341
          .head-ceil
            width 100%
            height .7rem
            display flex
            flex-direction column
            justify-content space-around
            border-right 1px solid #d48341
            & > div
              height 50%
            .standart-title
              width 100%
              border-top 1px solid #d48341
              border-bottom 1px solid #d48341
              & > div
                width 20%
                height 100%
                display flex
                justify-content center
                align-items center
                border-right 1px solid #d48341
                &:nth-child(5)
                  width 25%
                  flex-shrink 0
                  border-right none
      .promotion-data-wrapper
        .p-d-w-row
          & > div
            display flex
            justify-content center
            align-items center
            width 1.038rem
            height .3rem
            border-right: 1px solid #d48341;
            border-bottom: 1px solid #d48341;
            &:first-child
              width 1.38rem
              height .3rem
        .p-d-w-date
          .data-ceil-title
            width 1.0366rem
            height 1.2rem
            display flex
            justify-content center
            align-items center
            border-right: 1px solid #d48341;
            border-bottom: 1px solid #d48341;
            &.time4
              height 5.1rem
            &.time6
              height 1.498rem
          .data-ceil-ratio
            width 1.39rem
            display flex
            justify-content center
            align-items center
            border-right: 1px solid #d48341;
            border-bottom: 1px solid #d48341;
            &.time4
              flex-direction column
              & > div
                width 100%
                height 33.33%
                display flex
                justify-content center
                align-items center
                border-bottom 1px solid #d48341
                &:last-child
                  border-bottom none
    &.theme1
      color #cfcfcf
      .area-title
        border-bottom 1px solid #e6e081
        background-image linear-gradient(#FEF891,#FEF285,#FDE873,#FFE162)
      .item-table
        border-top: 1px solid #e6e081;
        border-left: 1px solid #e6e081;
      .item-table .item-r-w.item-t-w .item-c-w
        background-image linear-gradient(#FDF78F,#FCEB7C,#A6923F)
      .item-table .item-r-w .item-c-w
        border-right 1px solid #E6E081
        border-bottom 1px solid #E6E081
      .promotion-criteria
        border-top: 1px solid #e6e081;
        border-left: 1px solid #e6e081;
        .p-c-row
          &.head-row
            background-image linear-gradient(#FDF78F,#FCEB7C,#A6923F)
            .level-title
              border-right 1px solid #DBD57B
              border-bottom 1px solid #DBD57B
            .head-ceil
              .standart-title
                border-top 1px solid #DBD57B
                border-bottom 1px solid #DBD57B
                & > div
                  border-right 1px solid #DBD57B
        .promotion-data-wrapper
          .p-d-w-row > div
            border-right: 1px solid #e6e081;
            border-bottom: 1px solid #e6e081;
          .p-d-w-date
            .data-ceil-title
              border-right: 1px solid #e6e081;
              border-bottom: 1px solid #e6e081;
            .data-ceil-ratio
              border-right: 1px solid #e6e081;
              border-bottom: 1px solid #e6e081;
              &.time4 > div
                border-bottom 1px solid #e6e081
</style>
